import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";

// Imágenes
import Fondovuelosecreto from "../imgs/cuartos/vuelosecreto/Fondovuelosecreto.webp";
import logovuelosecreto from "../imgs/cuartos/vuelosecreto/logovuelosecreto.png";
import flechagris from "../imgs/cuartos/vuelosecreto/flechagris.png";
import flechaazul from "../imgs/cuartos/vuelosecreto/flechaazul.png";
import persona from "../imgs/cuartos/vuelosecreto/persona.png";
import maleta from "../imgs/cuartos/vuelosecreto/maleta.png";

const Sala = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { timeLeft } = useContext(TimerContext);
  const [rotationTop, setRotationTop] = useState(0);
  const [rotationBottom, setRotationBottom] = useState(0);
  const [error, setError] = useState("");

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handleRotation = (position) => {
    if (position === "top") {
      setRotationTop((prev) => (prev + 90) % 360);
    } else {
      setRotationBottom((prev) => (prev + 90) % 360);
    }
  };

  const handleConfirm = async () => {
    // Verificar si la flecha superior apunta a la izquierda (270°)
    // y la inferior hacia arriba (90°)
    if (rotationTop === 90 && rotationBottom === 0) {
      try {
        const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
        await updateDoc(userDocRef, { currentPhase: 7 });
        navigate("/FinalVueloSecreto");
      } catch (error) {
        setError("Ocurrió un error. Por favor, intenta de nuevo.");
      }
    } else {
      setError("La orientación de las flechas no es correcta.");
    }
  };

  return (
    <div className="min-h-screen relative">
      <img
        src={Fondovuelosecreto}
        alt="Fondo"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Logo */}
          <div className="flex flex-col items-center mb-8">
            <img src={logovuelosecreto} alt="Logo" className="w-48" />
          </div>

          {/* Nombre y texto */}
          <div className="p-8 mb-8">
            <h2 className="text-xl mb-4 font-bold">
              Nombre: {currentUser?.firstName || currentUser?.email}
            </h2>
            <p className="mb-6">
              Parece que ya conocen el destino de su viaje. Ahora solo les falta
              llegar al lugar donde encontrarán el transporte que los llevará a
              los hangares privados. Para ubicarlo, deberán encontrar la puerta
              C6 en el piso de abajo. Cuando la hayas localizado, sabrás cómo
              acomodar estas flechas.
            </p>
          </div>

          {/* Señales */}
          <div className="flex flex-col items-center space-y-4">
            {/* Primera señal */}
            <div className="bg-[#273067] p-6 w-[300px] flex items-center justify-between">
              <div
                className="bg-white rounded-full p-4 cursor-pointer"
                onClick={() => handleRotation("top")}
              >
                <img
                  src={flechagris}
                  alt="Flecha Superior"
                  className="w-20 h-20 transition-transform duration-300"
                  style={{ transform: `rotate(${rotationTop}deg)` }}
                />
              </div>
              <div className="bg-yellow-300 p-4 rounded-lg">
                <img src={persona} alt="Persona" className="w-16 h-16" />
              </div>
            </div>

            {/* Segunda señal */}
            <div className="bg-[#273067] p-6 w-[300px] flex items-center justify-between">
              <div
                className="bg-white rounded-full p-4 cursor-pointer"
                onClick={() => handleRotation("bottom")}
              >
                <img
                  src={flechaazul}
                  alt="Flecha Inferior"
                  className="w-20 h-20 transition-transform duration-300"
                  style={{ transform: `rotate(${rotationBottom}deg)` }}
                />
              </div>
              <div className="bg-yellow-300 p-4 rounded-lg">
                <img src={maleta} alt="Maleta" className="w-16 h-16" />
              </div>
            </div>
          </div>
          {/* Botón y mensaje de error */}
          <div className="flex flex-col items-center space-y-2 mt-12">
            <button
              onClick={handleConfirm}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-8 rounded-lg transition-colors"
            >
              Confirmar
            </button>
            {error && <p className="text-red-500 font-bold">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sala;
