// Loader.js
import React from "react";
import "../styles/loader.css";

const SimpleLoader = () => {
  return (
    <div className="loader">
      <div class="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default SimpleLoader;
