import React, { useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { cardDataVirtuales } from "../data";
import { useNavigate } from "react-router-dom";

import VirtualTermsPopup from "./popUps/virtTermsPopUp";
import LoginPopup from "./popUps/loginrequiredpopup";
import AlreadyCompletedPopup from "./popUps/AlreadyCompletedPopup";

import candado from "../imgs/candado-white.svg";
import { useAuth } from "./AuthProvider";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const FlipCardsVirtuales = () => {
  const [isMobile, setIsMobile] = useState(false);

  const [showTerms, setShowTerms] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showAlreadyCompletedPopup, setShowAlreadyCompletedPopup] =
    useState(false);

  const [selectedRoom, setSelectedRoom] = useState(null);
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [flippedStates, setFlippedStates] = useState(
    Array(cardDataVirtuales.length).fill(false)
  );

  const flipCard = (index) => {
    const newFlippedStates = [...flippedStates];
    newFlippedStates[index] = !newFlippedStates[index];
    setFlippedStates(newFlippedStates);
  };

  const collectionMap = {
    v1: "asesino_imitador",
    v2: "big_brother",
  };

  const handleCrackIn = async (room) => {
    if (currentUser) {
      const collectionName = collectionMap[room.id];
      try {
        const userDocRef = doc(db, collectionName, currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.bestTime) {
            setShowAlreadyCompletedPopup(true);
          } else if (userData.currentPhase) {
            navigateToPhase(userData.currentPhase, room.id);
          } else {
            setSelectedRoom(room);
            setShowTerms(true);
          }
        } else {
          setSelectedRoom(room);
          setShowTerms(true);
        }
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
        setSelectedRoom(room);
        setShowTerms(true);
      }
    } else {
      setSelectedRoom(room);
      setShowTerms(true);
    }
  };

  // Función para navegar a la fase correspondiente según room.id y currentPhase
  const navigateToPhase = (currentPhase, roomId) => {
    if (roomId === "v1") {
      // Fases para Asesino Imitador
      switch (currentPhase) {
        case 1:
          navigate("/introasesinoImitador");
          break;
        case 2:
          navigate("/paquete");
          break;
        case 3:
          navigate("/casainterior");
          break;
        case 4:
          navigate("/interiorcajafuerte");
          break;
        default:
          setShowTerms(true);
      }
    } else if (roomId === "v2") {
      // Fases para Big Brother
      switch (currentPhase) {
        case 1:
          navigate("/IntroBigBrother");
          break;
        case 2:
          navigate("/BlackBigBrother");
          break;
        case 3:
          navigate("/BigBrother");
          break;
        case 4:
          navigate("/PuntoPuntoPunto");
          break;
        default:
          setShowTerms(true);
      }
    }
  };

  // Manejar la aceptación de términos y condiciones
  const acceptTerms = () => {
    setShowTerms(false);
    if (selectedRoom) {
      navigateToPhase(1, selectedRoom.id);
    }
  };

  return (
    <div className="w-3/4 m-auto mt-16 md:mt-64">
      <Slider {...settings}>
        {cardDataVirtuales.map((card, index) => (
          <div key={index}>
            <ReactCardFlip
              flipDirection="horizontal"
              isFlipped={flippedStates[index]}
              flipSpeedBackToFront={0.8}
              flipSpeedFrontToBack={0.8}
            >
              <div
                className="card"
                onMouseEnter={!isMobile ? () => flipCard(index) : null}
                onClick={isMobile ? () => flipCard(index) : null}
              >
                <img src={card.imagen} alt={card.nombre} />
                <div className="card-bottom flex-col bg-gradient-to-b from-redCTC from-10% to-redCTCbuttons to-60%">
                  <div className="half red">
                    <p className="font-nebulica text-[1.6875rem]">
                      {card.nombre}
                    </p>
                  </div>
                  <div className="half blue">
                    <p className="text-white text-xs md:text-sm">
                      {card.dificultad}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="card card-back"
                onMouseLeave={!isMobile ? () => flipCard(index) : null}
                onClick={isMobile ? () => flipCard(index) : null}
              >
                <div className="flex bg-blueCTC items-center justify-center py-4 md:py-8">
                  <h2 className="text-white font-nebulica text-2xl md:text-4xl">
                    {card.nombre}
                  </h2>
                </div>
                <div className="px-4 py-4 font-roboto-mono">
                  <p className="text-white text-sm md:text-lg">
                    <strong className="text-sm md:text-lg font-nebulica">
                      Sinopsis: <br />
                    </strong>{" "}
                    {card.sinopsis}
                  </p>
                  <p className="text-white mt-2 md:mt-3.5 text-sm md:text-lg">
                    <strong className="text-sm md:text-lg font-nebulica">
                      Lugar:{" "}
                    </strong>{" "}
                    {card.lugar}
                  </p>
                  <p className="text-white mt-2 md:mt-3.5 text-sm md:text-lg">
                    <strong className="text-sm md:text-lg font-nebulica">
                      Tiempo:{" "}
                    </strong>{" "}
                    {card.tiempo}
                  </p>
                </div>
                <div className="flex items-center justify-center sm:mt-8">
                  <button
                    className="inline-flex h-8 sm:h-10 items-center justify-center rounded-md bg-blueCTC text-white text-sm sm:text-lg font-roboto-mono font-semibold px-4 md:px-6 gap-2"
                    onClick={() => handleCrackIn(card)}
                  >
                    <img src={candado} alt="Candado" className="h-6 w-6" />
                    Crack-In
                  </button>
                </div>
              </div>
            </ReactCardFlip>
          </div>
        ))}
      </Slider>

      {showTerms && selectedRoom && (
        <VirtualTermsPopup
          onAccept={acceptTerms}
          onClose={() => setShowTerms(false)}
          roomId={collectionMap[selectedRoom.id]}
          timeInMinutes={selectedRoom.timeInMinutes}
        />
      )}
      {showLoginPopup && (
        <LoginPopup onClose={() => setShowLoginPopup(false)} />
      )}
      {showAlreadyCompletedPopup && (
        <AlreadyCompletedPopup
          onClose={() => setShowAlreadyCompletedPopup(false)}
        />
      )}
    </div>
  );
};

export default FlipCardsVirtuales;
