import React from "react";
import location from "../../imgs/cuartos/Paciente0/location.png";

const BannerPaciente0 = ({ locationText }) => {
  return (
    <div className="flex items-center justify-center mt-2">
      <img src={location} alt="Logo" className="w-6 h-6" />
      <p className="text-white md:text-lg text-sm font-roboto-mono">
        Aeropuerto de Guadalajara Terminal 1 Salas A1 - A8
      </p>
    </div>
  );
};

export default BannerPaciente0;
