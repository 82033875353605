import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";

// Imágenes
import Fondovuelosecreto from "../imgs/cuartos/vuelosecreto/Fondovuelosecreto.webp";
import logovuelosecreto from "../imgs/cuartos/vuelosecreto/logovuelosecreto.png";
import candadoWhiteClosed from "../imgs/candado-white-closed.svg";

const Numeropantalla = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { timeLeft } = useContext(TimerContext);
  const [code, setCode] = useState(["F", "I", "D", "A", "", "", "", "2"]);
  const [error, setError] = useState("");

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCodeChange = (index, value) => {
    if (index === 4 || index === 5 || index === 6) {
      // Solo permitir cambios en los guiones
      const newCode = [...code];
      newCode[index] = value.toUpperCase();
      setCode(newCode);
    }
  };

  const handleConfirm = async () => {
    const submittedCode = code.join("");
    if (submittedCode === "FIDA1052") {
      try {
        const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
        await updateDoc(userDocRef, { currentPhase: 5 });
        setError("");
        navigate("/Pantalla");
      } catch (error) {
        console.error("Error al actualizar la fase:", error);
        setError("Ocurrió un error. Por favor, intenta de nuevo.");
      }
    } else {
      setError("Código incorrecto. Intenta de nuevo.");
    }
  };

  return (
    <div className="min-h-screen relative">
      {/* Fondo */}
      <img
        src={Fondovuelosecreto}
        alt="Fondo Vuelo Secreto"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Contenido */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Logo y título */}
          <div className="flex flex-col items-center mb-12">
            <img
              src={logovuelosecreto}
              alt="Logo Vuelo Secreto"
              className="w-48"
            />
          </div>

          {/* Texto principal */}
          <div className="p-8 mb-8">
            <h2 className="text-xl mb-4 font-bold">
              Nombre: {currentUser?.firstName || currentUser?.email}
            </h2>
            <p className="mb-6 font-bold">
              ¡Comunicación con la torre de control establecida con éxito!
            </p>
            <p className="mb-6">
              Después de recibir su solicitud especial, se ha autorizado el
              vuelo con destino a las coordenadas{" "}
              <span className="font-bold">18.526878, -64.358000</span>.
            </p>
            <p className="mb-6">
              Ahora, deberán averiguar a qué sala dirigirse para abordar el
              avión. Para ello, les recomendamos ubicar la pantalla con la
              información de vuelos de la terminal. Específicamente, aquella que
              está colocada encima de un extintor, ubicada entre la columna de
              la sala 7 y una tienda roja.
            </p>
            <p>
              Ingresa el identificador de la etiqueta que se encuentra detrás de
              la pantalla para acceder a la información actualizada.
            </p>
          </div>

          {/* Input de código */}
          <div className="flex justify-center mt-8">
            <div className="bg-gray-900 rounded-2xl px-8 py-4 flex items-center w-[550px]">
              <img
                src={candadoWhiteClosed}
                alt="Candado"
                className="w-10 h-10 text-white mr-6"
              />
              <div className="flex items-center space-x-2 w-full">
                {code.map((value, index) => {
                  // Determinar si es un espacio editable
                  const isEditable = index === 4 || index === 5 || index === 6;

                  // Aplicar estilos específicos basados en el tipo de carácter
                  const letterSpacing =
                    index === 3 ? "mr-6" : index === 6 ? "mr-6" : "";

                  return (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => handleCodeChange(index, e.target.value)}
                      disabled={!isEditable}
                      className={`w-12 h-14 text-center text-3xl font-bold 
                        ${
                          isEditable
                            ? "border-b-4 border-white bg-transparent text-white"
                            : "bg-transparent text-white border-none"
                        } 
                        ${letterSpacing}
                        focus:outline-none focus:border-white
                        ${isEditable ? "cursor-text" : "cursor-default"}`}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          {/* Botón Confirmar y Mensaje de Error */}
          <div className="flex flex-col items-center space-y-2 mt-8">
            <button
              onClick={handleConfirm}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-lg transition-colors"
            >
              Confirmar
            </button>
            {error && <p className="text-red-500 font-bold">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numeropantalla;
