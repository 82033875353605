import React, { useContext, useEffect } from "react";
import { TimerContext } from "./timerContext_new";
import { useAuth } from "./AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

// Imágenes
import backgroundImage from "../imgs/people_background.webp";
import timeRedIcon from "../imgs/timer-red.svg";
import impresora from "../imgs/cuartos/Paciente0/impresora.webp";

// Componentes
import BannerPaciente0 from "./paciente0/bannerpaciente0";

const Impresora = () => {
  const { timeLeft } = useContext(TimerContext);
  const { currentUser } = useAuth();

  // Efecto para guardar el tiempo cada segundo
  useEffect(() => {
    const saveTime = async () => {
      if (currentUser && timeLeft) {
        const userDocRef = doc(db, "paciente_0", currentUser.uid);
        try {
          await updateDoc(userDocRef, {
            currentTime: timeLeft,
          });
        } catch (error) {
          console.error("Error al guardar el tiempo:", error);
        }
      }
    };

    // Guardar el tiempo cada segundo
    const interval = setInterval(saveTime, 1000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, [currentUser, timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div
      className="min-h-screen bg-blueCTCbg bg-cover bg-top bg-no-repeat flex flex-col items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute top-8 text-center">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-white text-5xl font-bold bg-redCTC px-8 py-2">
            Paciente 0
          </h1>
        </div>
        <BannerPaciente0 />
        <div className="flex items-center justify-center p-2 rounded-md shadow-md text-white font-roboto-mono">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center mx-4 mt-28">
        <img src={impresora} alt="Impresora" className="w-[500px] h-auto" />
      </div>
    </div>
  );
};

export default Impresora;
