import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";
import { TimerContext } from "./timerContext_new";

// Imágenes
import backgroundImage from "../imgs/people_background.webp";
import sobre from "../imgs/cuartos/Paciente0/sobre.webp";
import sobreAbierto from "../imgs/cuartos/Paciente0/sobreabierto.webp";
import timeRedIcon from "../imgs/timer-red.svg";

// Firebase
import { useAuth } from "./AuthProvider";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import BannerPaciente0 from "./paciente0/bannerpaciente0";

const IntroPaciente0 = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState("");
  const [isSobreOpen, setIsSobreOpen] = useState(false);
  const navigate = useNavigate();
  const { timeLeft, startTimer } = useContext(TimerContext);
  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePasswordSubmit = async (password) => {
    if (password === "0010") {
      try {
        if (currentUser) {
          const userDocRef = doc(db, "paciente_0", currentUser.uid);
          await updateDoc(userDocRef, { currentPhase: 2 });
        }

        setTimeout(() => {
          setShowPopup(false);
          setError("");
          navigate("/PlanosGDL");
        }, 3000);
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      setError("Contraseña incorrecta. Intenta de nuevo.");
    }
  };

  const toggleSobre = () => {
    setIsSobreOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const timerStarted = localStorage.getItem("timerStartTime");
    if (!timerStarted) {
      startTimer();
    }
  }, [startTimer]);

  useEffect(() => {
    const setInitialPhase = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "paciente0", currentUser.uid);
        await setDoc(userDocRef, { currentPhase: 1 }, { merge: true });
      }
    };
    setInitialPhase();
  }, [currentUser]);

  return (
    <div
      className="min-h-screen bg-blueCTCbg bg-cover bg-top bg-no-repeat flex flex-col items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute top-8 text-center">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-white text-5xl font-bold bg-redCTC px-8 py-2">
            Paciente 0
          </h1>
        </div>
        <BannerPaciente0 />
        <div className="flex items-center justify-center p-2 rounded-md shadow-md text-white font-roboto-mono">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
      </div>

      {/* Sobre cerrado */}
      {!isSobreOpen && (
        <div
          className="flex flex-col items-center justify-center cursor-pointer max-w-xl mx-4"
          onClick={toggleSobre}
        >
          <img src={sobre} alt="Sobre cerrado" className="w-full" />
        </div>
      )}

      {/* Sobre abierto con animación y nombre del usuario */}
      {isSobreOpen && (
        <div className="relative flex flex-col items-center justify-center max-w-xl mt-48 mb-12 mx-4">
          <img src={sobreAbierto} alt="Sobre abierto" className="w-full mb-4" />
          <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <p className="text-gray-800 text-lg sm:text-xl font-bold mb-32 sm:mb-40 md:mb-44 font-roboto-mono">
              {currentUser?.firstName || currentUser?.email}
            </p>
          </div>
          <ContinueButton
            setShowPopup={setShowPopup}
            bgColor="bg-greenPaciente0"
          />
        </div>
      )}

      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa la contraseña"
          buttonText="Verificar"
          error={error}
        />
      )}
    </div>
  );
};

export default IntroPaciente0;
