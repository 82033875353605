import React, { useState, useEffect, useContext } from "react";
import Fondovuelosecreto from "../imgs/cuartos/vuelosecreto/Fondovuelosecreto.webp";
import logovuelosecreto from "../imgs/cuartos/vuelosecreto/logovuelosecreto.png";
import ID from "../imgs/cuartos/vuelosecreto/ID.webp";
import FMM from "../imgs/cuartos/vuelosecreto/FMM.png";
import PasswordPopup from "./popUps/passwordPopup";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../stripeConfig";
import PaymentPopup from "./popUps/paymentPopup";
import { useAuth } from "./AuthProvider";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { cardData } from "../data";
import { useNavigate } from "react-router-dom";
import candadowhiteclosed from "../imgs/candado-white-closed.svg";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";
import LoginPopup from "./popUps/loginrequiredpopup";

const Documentos = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { timeLeft, restartTimer, startTimer } = useContext(TimerContext);
  const [code, setCode] = useState(Array(8).fill(""));
  const [showIDImage, setShowIDImage] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const [error, setError] = useState("");
  const [isTimerInitialized, setIsTimerInitialized] = useState(false);

  // Obtener el paymentTime del cuarto "Vuelo Secreto"
  const vueloSecretoData = cardData.find((room) => room.id === "h3");
  const paymentTime = vueloSecretoData?.paymentTime || 40;

  // Efecto para recuperar el tiempo guardado solo una vez al inicio
  useEffect(() => {
    const getStoredTime = async () => {
      if (currentUser && !isTimerInitialized) {
        const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
        try {
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.currentTime) {
              restartTimer(userData.currentTime);
              startTimer();
              setIsTimerInitialized(true);
            }
          }
        } catch (error) {
          console.error("Error al recuperar el tiempo:", error);
        }
      }
    };

    getStoredTime();
  }, [currentUser, restartTimer, startTimer, isTimerInitialized]);

  // Efecto para guardar el tiempo cada segundo
  useEffect(() => {
    const saveTime = async () => {
      if (currentUser && timeLeft && isTimerInitialized) {
        const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
        try {
          await updateDoc(userDocRef, {
            currentTime: timeLeft,
          });
        } catch (error) {
          console.error("Error al guardar el tiempo:", error);
        }
      }
    };

    const interval = setInterval(saveTime, 1000);
    return () => clearInterval(interval);
  }, [currentUser, timeLeft, isTimerInitialized]);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!currentUser) {
        setShowLoginPopup(true);
        return;
      }

      const docRef = doc(db, "vuelo_secreto", currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const userHasPaid = data.hasPaid || false;
        setHasPaid(userHasPaid);
        setShowPaymentPopup(!userHasPaid);
      } else {
        await setDoc(docRef, { currentPhase: 2, hasPaid: false });
        setShowPaymentPopup(true);
      }
    };

    checkPaymentStatus();
  }, [currentUser]);

  const handleIDClick = () => {
    if (!showIDImage) {
      setShowPasswordPopup(true);
    }
  };

  const handlePasswordSubmit = (password) => {
    if (password === "MARK") {
      setShowIDImage(true);
      setShowPasswordPopup(false);
      setError("");
      if (!currentUser) {
        setShowLoginPopup(true);
      } else if (!hasPaid) {
        setShowPaymentPopup(true);
      }
    } else {
      setError("Contraseña incorrecta. Intenta de nuevo.");
    }
  };

  const handleLoginSuccess = async () => {
    setShowLoginPopup(false);
    if (currentUser) {
      const docRef = doc(db, "vuelo_secreto", currentUser.uid);
      await setDoc(
        docRef,
        { currentPhase: 2, hasPaid: false },
        { merge: true }
      );
    }
    setShowPaymentPopup(true);
  };

  const handleConfirm = async () => {
    const submittedCode = code.join("");
    if (submittedCode === "051950P1") {
      try {
        if (currentUser) {
          const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
          await updateDoc(userDocRef, { currentPhase: 3 });
        }
        setError("");
        navigate("/Torredecontrol");
      } catch (error) {
        console.error("Error al actualizar la fase:", error);
        setError("Ocurrió un error. Por favor, intenta de nuevo.");
      }
    } else {
      setError("Código incorrecto. Intenta de nuevo.");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // Timer display actualizado para mostrar cambios en tiempo real
  const TimerDisplay = () => (
    <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
      <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
      <span className="text-lg" key={timeLeft}>
        {formatTime(timeLeft)}
      </span>
    </div>
  );

  return (
    <div className="min-h-screen relative">
      {/* Fondo */}
      <img
        src={Fondovuelosecreto}
        alt="Fondo Vuelo Secreto"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Timer */}
      <TimerDisplay />

      {/* Contenido */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Logo y título */}
          <div className="flex flex-col items-center mb-12">
            <img
              src={logovuelosecreto}
              alt="Logo Vuelo Secreto"
              className="w-48"
            />
          </div>

          {/* Texto introductorio */}
          <div className="text-black mb-8">
            <p className="font-roboto-mono text-center text-lg mb-8 font-bold">
              Estimado pasajero,
            </p>
            <p className="font-roboto-mono text-center mb-8">
              Para garantizar que pueda abordar su vuelo sin inconvenientes, le
              solicitamos que complete su forma migratoria con los datos
              correctos. Esto es esencial para asegurar un proceso de embarque
              fluido y sin contratiempos.
            </p>
          </div>

          {/* Documentos */}
          <div className="flex flex-col items-center space-y-8">
            {/* Pasaporte */}
            <div
              className="w-full max-w-2xl relative cursor-pointer"
              onClick={handleIDClick}
            >
              <img
                src={ID}
                alt="Pasaporte"
                className={`w-full h-auto rounded-lg shadow-lg transition-opacity duration-500 ${
                  showIDImage ? "opacity-100" : "opacity-0"
                }`}
              />
              {!showIDImage && (
                <div className="absolute inset-0 bg-black rounded-lg shadow-lg flex items-center justify-center">
                  <p className="text-white text-lg font-roboto-mono">
                    Click para revelar el documento
                  </p>
                </div>
              )}
            </div>

            {/* Forma Migratoria */}
            <div className="w-full max-w-2xl">
              <img
                src={FMM}
                alt="Forma Migratoria"
                className="w-full h-auto shadow-lg"
              />
            </div>
          </div>

          {/* Input de código */}
          <div className="flex justify-center mt-8">
            <div className="bg-gray-900 rounded-tr-2xl rounded-bl-2xl px-4 py-3 flex items-center">
              <img
                src={candadowhiteclosed}
                alt="CandadoWhiteClosed"
                className="w-8 h-8 mr-4"
              />
              <div className="flex space-x-1">
                {code.map((value, index) => {
                  let borderColor = "border-gray-300";
                  let backgroundColor = "bg-white";
                  // Asignar colores según la posición
                  if (index < 2) borderColor = "border-green-500";
                  else if (index < 4) {
                    borderColor = "border-purple-400";
                    backgroundColor = "bg-purple-300";
                  } else if (index < 6) {
                    borderColor = "border-purple-400";
                    backgroundColor = "bg-white";
                  } else {
                    borderColor = "border-orange-400";
                    backgroundColor = "bg-white";
                  }

                  return (
                    <div key={index} className="relative">
                      <input
                        type="text"
                        maxLength="1"
                        value={value}
                        onChange={(e) => {
                          const newCode = [...code];
                          newCode[index] = e.target.value.toUpperCase();
                          setCode(newCode);

                          // Mover al siguiente input si hay un valor
                          if (e.target.value && index < 7) {
                            const nextInput =
                              e.target.parentElement.nextElementSibling?.querySelector(
                                "input"
                              );
                            if (nextInput) nextInput.focus();
                          }
                        }}
                        onKeyDown={(e) => {
                          if (
                            e.key === "Backspace" &&
                            !code[index] &&
                            index > 0
                          ) {
                            const prevInput =
                              e.target.parentElement.previousElementSibling?.querySelector(
                                "input"
                              );
                            if (prevInput) prevInput.focus();
                          }
                        }}
                        className={`w-10 h-12 text-center border-2 ${borderColor} rounded-md 
                          ${backgroundColor} text-black text-xl font-bold focus:outline-none focus:ring-2 `}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Botón Confirmar y Mensaje de Error */}
          <div className="flex flex-col items-center space-y-2 my-8">
            <button
              onClick={handleConfirm}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-lg transition-colors"
            >
              Confirmar
            </button>
            {error && <p className="text-red-500 font-bold">{error}</p>}
          </div>
        </div>
      </div>

      {/* Password Popup */}
      {showPasswordPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPasswordPopup(false)}
          titleText="Ingresa la contraseña para revelar el documento"
          buttonText="Verificar"
          error={error}
        />
      )}

      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          onLoginSuccess={handleLoginSuccess}
          currentPhase={2}
        />
      )}

      {showPaymentPopup && !hasPaid && (
        <Elements stripe={stripePromise}>
          <PaymentPopup
            onClose={() => setShowPaymentPopup(false)}
            collectionName="vuelo_secreto"
            phaseNumber={2}
            amount={79}
            originalAmount={189}
            paymentTime={paymentTime}
          />
        </Elements>
      )}
    </div>
  );
};

export default Documentos;
