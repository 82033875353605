import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";

// Imágenes
import Fondovuelosecreto from "../imgs/cuartos/vuelosecreto/Fondovuelosecreto.webp";
import logovuelosecreto from "../imgs/cuartos/vuelosecreto/logovuelosecreto.png";
import candadowhiteclosed from "../imgs/candado-white-closed.svg";

const Torredecontrol = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { timeLeft } = useContext(TimerContext);
  const [binaryCode, setBinaryCode] = useState(["0", "0", "0", "0"]);
  const [error, setError] = useState("");

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const toggleBinary = (index) => {
    const newCode = [...binaryCode];
    newCode[index] = newCode[index] === "0" ? "1" : "0";
    setBinaryCode(newCode);
  };

  const handleConfirm = async () => {
    const submittedCode = binaryCode.join("");
    if (submittedCode === "0101") {
      try {
        const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
        await updateDoc(userDocRef, { currentPhase: 4 });
        setError("");
        navigate("/Numeropantalla");
      } catch (error) {
        console.error("Error al actualizar la fase:", error);
        setError("Ocurrió un error. Por favor, intenta de nuevo.");
      }
    } else {
      setError("Código incorrecto. Intenta de nuevo.");
    }
  };

  return (
    <div className="min-h-screen relative">
      {/* Fondo */}
      <img
        src={Fondovuelosecreto}
        alt="Fondo Vuelo Secreto"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Contenido */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Logo y título */}
          <div className="flex flex-col items-center mb-12">
            <img
              src={logovuelosecreto}
              alt="Logo Vuelo Secreto"
              className="w-48"
            />
          </div>

          {/* Texto principal */}
          <div className="p-8 mb-8">
            <h2 className="text-xl mb-4 font-bold">
              Nombre: {currentUser?.firstName || currentUser?.email}
            </h2>
            <p className="mb-6">
              Sus datos han sido corroborados con éxito. Ahora, deberán
              establecer contacto con la torre de control para continuar.
            </p>
            <p className="mb-6">
              Diríjanse a la última sala hacia el{" "}
              <span className="font-bold">Este</span>, donde podrán tener acceso
              al radio de comunicación con ellos. Asegúrense de tener contacto
              visual con la torre.
            </p>
            <p>
              Una vez allí, escribe el número de la sala en la que te encuentras
              en formato binario para iniciar la conversación y poder solicitar
              el uso de pista.
            </p>
          </div>

          {/* Input binario */}
          <div className="flex justify-center mt-8">
            <div className="bg-gray-900 rounded-tr-2xl rounded-bl-2xl px-4 py-3 flex items-center">
              <img
                src={candadowhiteclosed}
                alt="CandadoWhiteClosed"
                className="w-8 h-8 mr-4"
              />
              <div className="flex space-x-2">
                {binaryCode.map((digit, index) => (
                  <button
                    key={index}
                    onClick={() => toggleBinary(index)}
                    className="w-12 h-14 bg-white rounded-md text-2xl font-bold focus:outline-none hover:bg-gray-100 transition-colors"
                  >
                    {digit}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Botón Confirmar y Mensaje de Error */}
          <div className="flex flex-col items-center space-y-2 mt-8">
            <button
              onClick={handleConfirm}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-lg transition-colors"
            >
              Confirmar
            </button>
            {error && <p className="text-red-500 font-bold">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Torredecontrol;
