import React from "react";

//PopUp que se muestra cuando el usuario entra a la aplicación por primera vez
//Creado por: Alberto Cebreros

const WelcomePopup = ({ onClose, openSignIn }) => {
  const handleStartGame = () => {
    openSignIn();
    onClose();
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          ¡Bienvenido, crack!
        </h2>
        <p className="text-white mb-4">
          Parece que lograste lo que pocos pueden… ¿Tienes el código? Entonces
          sigue las instrucciones y prepárate para el desafío:
        </p>
        <ul className="text-white mb-4 list-disc list-inside">
          <li>
            Crea tu cuenta (porque los genios merecen que su progreso quede
            registrado).
          </li>
          <li>Elige una sala y prepárate para poner a prueba tu mente.</li>
          <li>Aplica tu cupón y juega. Porque los que piensan, ganan.</li>
        </ul>
        <p className="text-white mb-4">
          💥 ¿Estás listo para demostrar de qué estás hecho?
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50"
            onClick={handleStartGame}
          >
            ¡El juego comienza ahora!
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomePopup;
