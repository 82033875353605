import React, { useState, useContext } from "react";
import TableroCamiones from "./vuelosecreto/tablerocamiones";
import { useAuth } from "./AuthProvider";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";
import SuccessPopup from "./popUps/successPopup";
import diploma from "../imgs/diplomas/Diplomas_vuelo-secreto.webp";

// Imágenes
import Fondovuelosecreto from "../imgs/cuartos/vuelosecreto/Fondovuelosecreto.webp";
import logovuelosecreto from "../imgs/cuartos/vuelosecreto/logovuelosecreto.png";

const FinalVueloSecreto = () => {
  const { currentUser } = useAuth();
  const { timeLeft } = useContext(TimerContext);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const calculateTimeElapsed = () => {
    const totalTime = 30 * 60;
    const timeElapsedInSeconds = totalTime - timeLeft;
    return formatTime(timeElapsedInSeconds);
  };

  return (
    <div className="min-h-screen relative">
      {/* Fondo */}
      <img
        src={Fondovuelosecreto}
        alt="Fondo Vuelo Secreto"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Contenido */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Logo y título */}
          <div className="flex flex-col items-center mb-8">
            <img
              src={logovuelosecreto}
              alt="Logo Vuelo Secreto"
              className="w-48 mb-6"
            />
          </div>

          {/* Nombre del usuario */}
          <div className="text-black mb-8">
            <p className="font-roboto-mono">
              Nombre: {currentUser?.firstName || currentUser?.email}
            </p>
          </div>

          {/* Descripción */}
          <div className="rounded-lg">
            <p className="text-black font-roboto-mono leading-relaxed">
              Has logrado llegar al hangar privado, pero parece que el avión
              está atascado en la pista debido a una falla de comunicación. Para
              que el avión pueda llegar a la puerta, deberás mover los camiones
              estratégicamente para despejar su camino.
            </p>
          </div>

          {/* Tablero de juego */}
          <div className="my-12">
            <TableroCamiones setShowSuccessPopup={setShowSuccessPopup} />
          </div>

          {/* Instrucciones */}
          <div className="rounded-lg text-center mb-12">
            <p className="text-black font-roboto-mono mb-2">
              Ingresa la secuencia correcta de los movimientos de cada camión
              con el siguiente formato:
            </p>
            <p className="text-black font-roboto-mono font-bold">351421</p>
          </div>
        </div>
      </div>

      {/* Popup de victoria */}
      {showSuccessPopup && (
        <SuccessPopup
          onClose={() => setShowSuccessPopup(false)}
          timeElapsed={calculateTimeElapsed()}
          imageUrl={diploma}
          message="¡Felicitaciones! Has completado el escape room del Vuelo Secreto."
          roomId="vuelo_secreto"
        />
      )}
    </div>
  );
};

export default FinalVueloSecreto;
