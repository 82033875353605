import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { TimerContext } from "../timerContext_new";

// Importar imágenes
import tablero from "../../imgs/cuartos/vuelosecreto/Tablero.png";
import avion from "../../imgs/cuartos/vuelosecreto/Avion.png";
import camion1 from "../../imgs/cuartos/vuelosecreto/Camion_1.png";
import camion2 from "../../imgs/cuartos/vuelosecreto/Camion_2.png";
import camion3 from "../../imgs/cuartos/vuelosecreto/Camion_3.png";
import camion4 from "../../imgs/cuartos/vuelosecreto/Camion_4.png";
import camion5 from "../../imgs/cuartos/vuelosecreto/Camion_5.png";
import btnLeft from "../../imgs/cuartos/vuelosecreto/Left.png";
import btnRight from "../../imgs/cuartos/vuelosecreto/Right.png";
import btnUp from "../../imgs/cuartos/vuelosecreto/Up.png";
import btnDown from "../../imgs/cuartos/vuelosecreto/Down.png";

const TableroCamiones = ({ setShowSuccessPopup }) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { timeLeft, stopTimer } = useContext(TimerContext);

  // Definir las dimensiones del área jugable
  const BOARD_MARGIN = {
    top: 60,
    left: 60,
    right: 70,
    bottom: 70,
  };

  const BOARD_SIZE = 500;
  const PLAYABLE_SIZE = BOARD_SIZE - BOARD_MARGIN.left - BOARD_MARGIN.right;
  const CELL_SIZE = PLAYABLE_SIZE / 6;

  // Estado de vehículos
  const [vehiclesData, setVehiclesData] = useState({
    camion1: {
      id: "camion1",
      img: camion1,
      spaces: [
        [0, 0],
        [0, 1],
        [0, 2],
      ],
      isHorizontal: true,
      width: CELL_SIZE * 3,
      height: CELL_SIZE,
      top: BOARD_MARGIN.top + 0 * CELL_SIZE,
      left: BOARD_MARGIN.left + 0 * CELL_SIZE,
    },
    camion2: {
      id: "camion2",
      img: camion2,
      spaces: [
        [1, 2],
        [2, 2],
        [3, 2],
      ],
      isHorizontal: false,
      width: CELL_SIZE,
      height: CELL_SIZE * 3,
      top: BOARD_MARGIN.top + 1 * CELL_SIZE,
      left: BOARD_MARGIN.left + 2 * CELL_SIZE,
    },
    camion3: {
      id: "camion3",
      img: camion3,
      spaces: [
        [4, 0],
        [4, 1],
        [4, 2],
      ],
      isHorizontal: true,
      width: CELL_SIZE * 3,
      height: CELL_SIZE,
      top: BOARD_MARGIN.top + 4 * CELL_SIZE,
      left: BOARD_MARGIN.left + 0 * CELL_SIZE,
    },
    camion4: {
      id: "camion4",
      img: camion4,
      spaces: [
        [3, 4],
        [3, 5],
      ],
      isHorizontal: true,
      width: CELL_SIZE * 2,
      height: CELL_SIZE,
      top: BOARD_MARGIN.top + 3 * CELL_SIZE,
      left: BOARD_MARGIN.left + 4 * CELL_SIZE,
    },
    camion5: {
      id: "camion5",
      img: camion5,
      spaces: [
        [4, 5],
        [5, 5],
      ],
      isHorizontal: false,
      width: CELL_SIZE,
      height: CELL_SIZE * 2,
      top: BOARD_MARGIN.top + 4 * CELL_SIZE,
      left: BOARD_MARGIN.left + 5 * CELL_SIZE,
    },
    avion: {
      id: "avion",
      img: avion,
      spaces: [[2, 0]],
      isHorizontal: true,
      width: CELL_SIZE,
      height: CELL_SIZE,
      top: BOARD_MARGIN.top + 2 * CELL_SIZE,
      left: BOARD_MARGIN.left + 0 * CELL_SIZE,
    },
  });

  // Grid para visualizar ocupación
  const [grid, setGrid] = useState(
    Array(6)
      .fill()
      .map(() => Array(6).fill(null))
  );

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  // Construye el grid en base a vehiclesData
  const buildGrid = (vehicles) => {
    const newGrid = Array(6)
      .fill()
      .map(() => Array(6).fill(null));
    Object.values(vehicles).forEach((v) => {
      v.spaces.forEach(([row, col]) => {
        newGrid[row][col] = v.id;
      });
    });
    return newGrid;
  };

  // Cada vez que cambien los vehículos, recalcula el grid
  useEffect(() => {
    setGrid(buildGrid(vehiclesData));
  }, [vehiclesData]);

  // Verifica colisiones
  const checkCollision = (newSpaces) => {
    // Verificar límites del tablero
    if (
      newSpaces.some(([row, col]) => row < 0 || row >= 6 || col < 0 || col >= 6)
    ) {
      return true;
    }

    // Verificar colisiones con otros vehículos (usando la grilla actual)
    return newSpaces.some(([row, col]) => {
      const occupant = grid[row][col];
      // Si hay alguien ahí y no es el vehículo seleccionado
      return occupant && occupant !== selectedVehicle;
    });
  };

  const calculateTimeElapsed = () => {
    const totalTime = 30 * 60;
    const timeElapsedInSeconds = totalTime - timeLeft;
    const minutes = Math.floor(timeElapsedInSeconds / 60);
    const secs = timeElapsedInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // Función para mover vehículo
  const moveVehicle = async (direction) => {
    if (!selectedVehicle) return;

    const vehicle = vehiclesData[selectedVehicle];
    let deltaRow = 0;
    let deltaCol = 0;

    switch (direction) {
      case "left":
        deltaCol = -1;
        break;
      case "right":
        deltaCol = 1;
        break;
      case "up":
        deltaRow = -1;
        break;
      case "down":
        deltaRow = 1;
        break;
      default:
        return;
    }

    // Calcular nuevas celdas donde iría
    const newSpaces = vehicle.spaces.map(([row, col]) => [
      row + deltaRow,
      col + deltaCol,
    ]);

    // Verificar colisión
    if (!checkCollision(newSpaces)) {
      // Crear una copia actualizada del vehículo
      const updatedVehicle = {
        ...vehicle,
        spaces: newSpaces,
        top: BOARD_MARGIN.top + newSpaces[0][0] * CELL_SIZE,
        left: BOARD_MARGIN.left + newSpaces[0][1] * CELL_SIZE,
      };

      // Actualizar estado con un nuevo objeto de vehículos
      setVehiclesData((prevVehicles) => ({
        ...prevVehicles,
        [selectedVehicle]: updatedVehicle,
      }));

      // Verificar victoria si es el avión y está en la posición correcta
      if (
        selectedVehicle === "avion" &&
        newSpaces[0][0] === 2 &&
        newSpaces[0][1] === 5
      ) {
        try {
          const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
          const timeElapsed = calculateTimeElapsed();

          await updateDoc(userDocRef, {
            currentPhase: 8,
            completionTime: timeElapsed,
            isCompleted: true,
          });

          stopTimer();
          setShowSuccessPopup(true);
        } catch (error) {
          console.error("Error al actualizar estado:", error);
        }
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="relative w-[500px] h-[500px]">
        <img
          src={tablero}
          alt="Tablero"
          className="w-full h-full object-contain"
        />

        {Object.values(vehiclesData).map((vehicle) => (
          <div
            key={vehicle.id}
            onClick={() => setSelectedVehicle(vehicle.id)}
            className={`absolute cursor-pointer transition-all duration-300 ${
              selectedVehicle === vehicle.id
                ? "scale-105 border-2 border-yellow-400"
                : ""
            }`}
            style={{
              width: vehicle.width + "px",
              height: vehicle.height + "px",
              top: vehicle.top + "px",
              left: vehicle.left + "px",
              position: "absolute",
              transition: "all 0.3s ease",
            }}
          >
            <img
              src={vehicle.img}
              alt={vehicle.id}
              className="w-full h-full object-contain"
            />
          </div>
        ))}
      </div>

      {/* Controles de movimiento */}
      <div className="grid grid-cols-3 gap-2 w-[150px]">
        <img
          src={btnLeft}
          alt="Left"
          className="cursor-pointer hover:scale-110 transition-transform"
          onClick={() => moveVehicle("left")}
        />
        <div className="grid grid-rows-2 gap-2">
          <img
            src={btnUp}
            alt="Up"
            className="cursor-pointer hover:scale-110 transition-transform"
            onClick={() => moveVehicle("up")}
          />
          <img
            src={btnDown}
            alt="Down"
            className="cursor-pointer hover:scale-110 transition-transform"
            onClick={() => moveVehicle("down")}
          />
        </div>
        <img
          src={btnRight}
          alt="Right"
          className="cursor-pointer hover:scale-110 transition-transform"
          onClick={() => moveVehicle("right")}
        />
      </div>
    </div>
  );
};

export default TableroCamiones;
