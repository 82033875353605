import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";

import { AuthProvider } from "./components/AuthProvider";
import { TimerProvider } from "./components/timerContext_new";
import Loader from "./components/loader";
import SimpleLoader from "./components/SimpleLoader";
import Component from "./components/Component";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Terms from "./components/terms";
import Privacy from "./components/privacy";
import Profile from "./components/Profile";
import AboutUs from "./components/aboutUs";
import Testimonials from "./components/testimonials";
import EscapeRooms from "./components/escapeRooms";
import FlipCards from "./components/FlipCards";
import FAQ from "./components/faq";
import Contact from "./components/contact";

//Importaciones de Asesino Imitador
import AsesinoImitador from "./components/introasesinoImitador";
import AsesinoImitador2 from "./components/paquete";
import AsesinoImitador3 from "./components/casainterior";
import AsesinoImitador4 from "./components/interiorcajafuerte";

//Importaciones de Club 42
import IntroClub42 from "./components/IntroClub42";
import Sala19 from "./components/sala19";
import MelodiaIdentificada from "./components/melodiaIdentificada";
import Menu from "./components/menu";
import DM from "./components/dm";
import Elevador from "./components/elevador";

//Importaciones de Paciente 0
import IntroPaciente0 from "./components/IntroPaciente0";
import PlanosGDL from "./components/PlanosGDL";
import MaquinaDispensadora from "./components/MaquinaDispensadora";
import Prescripcion from "./components/Prescripcion";
import Crucigrama from "./components/Crucigrama";
import Impresora from "./components/Impresora";
import FinalPaciente0 from "./components/FinalPaciente0";

//Importaciones de Vuelo Secreto
import IntroVueloSecreto from "./components/IntroVueloSecreto";
import FinalVueloSecreto from "./components/FinalVueloSecreto";
import Documentos from "./components/Documentos";
import Torredecontrol from "./components/Torredecontrol";
import Numeropantalla from "./components/Numeropantalla";
import Pantalla from "./components/Pantalla";
import Sala from "./components/Sala";

//Importaciones de Big Brother
import IntroBigBrother from "./components/IntroBigBrother";
import BigBrother from "./components/BigBrother";
import BlackBigBrother from "./components/BlackBigBrother";
import PuntoPuntoPunto from "./components/puntopuntopunto";
import StripeContainer from "./StripeContainer";

//Importación para usar Analytics de Vercel
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const routesWithLoader = useMemo(
    () => [
      "/introasesinoImitador",
      "/paquete",
      "/casainterior",
      "/interiorcajafuerte",
      "/IntroBigBrother",
      "/BlackBigBrother",
      "/BigBrother",
      "/PuntoPuntoPunto",
      "/IntroClub42",
      "/Sala19",
      "/IntroPaciente0",
      "/IntroVueloSecreto",
      "/aboutUs",
      "/profile",
      "/",
    ],
    []
  );

  // Verifica si la pantalla es mas grande que md y si es asi, muestra el loader
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMediumScreen(window.innerWidth >= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    // Verifica si la ruta actual está en el array de rutas con loader
    if (routesWithLoader.includes(location.pathname)) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      setLoading(false);
    }
  }, [location.pathname, routesWithLoader]);

  return (
    <AuthProvider>
      <TimerProvider>
        <StripeContainer>
          {loading && (isMediumScreen ? <Loader /> : <SimpleLoader />)}
          {/* Muestra el loader solo si está en las rutas especificadas y en pantallas mayores a md */}
          <Routes>
            <Route path="/" element={<Component />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/escapeRooms" element={<EscapeRooms />} />
            <Route path="/flipCards" element={<FlipCards />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />

            {/* Rutas protegidas para "Asesino Imitador" */}
            <Route path="/introasesinoImitador" element={<AsesinoImitador />} />
            <Route path="/paquete" element={<AsesinoImitador2 />} />
            <Route
              path="/casainterior"
              element={
                <ProtectedRoute requiredPhase={3} roomId="asesino_imitador">
                  <AsesinoImitador3 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/interiorcajafuerte"
              element={
                <ProtectedRoute requiredPhase={4} roomId="asesino_imitador">
                  <AsesinoImitador4 />
                </ProtectedRoute>
              }
            />

            {/* Rutas protegidas para "Big Brother" */}
            <Route path="/IntroBigBrother" element={<IntroBigBrother />} />
            <Route path="/BlackBigBrother" element={<BlackBigBrother />} />
            <Route
              path="/BigBrother"
              element={
                <ProtectedRoute requiredPhase={3} roomId="big_brother">
                  <BigBrother />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PuntoPuntoPunto"
              element={
                <ProtectedRoute requiredPhase={4} roomId="big_brother">
                  <PuntoPuntoPunto />
                </ProtectedRoute>
              }
            />

            {/* Rutas para "Club 42" */}
            <Route path="/IntroClub42" element={<IntroClub42 />} />
            <Route path="/Sala19" element={<Sala19 />} />
            <Route
              path="/melodiaidentificada"
              element={<MelodiaIdentificada />}
            />
            <Route
              path="/menu"
              element={
                <ProtectedRoute requiredPhase={4} roomId="club_42">
                  <Menu />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dm"
              element={
                <ProtectedRoute requiredPhase={5} roomId="club_42">
                  <DM />
                </ProtectedRoute>
              }
            />
            <Route
              path="/elevador"
              element={
                <ProtectedRoute requiredPhase={5} roomId="club_42">
                  <Elevador />
                </ProtectedRoute>
              }
            />

            {/* Rutas protegidas para Paciente 0 */}

            <Route path="/IntroPaciente0" element={<IntroPaciente0 />} />
            <Route path="/PlanosGDL" element={<PlanosGDL />} />
            <Route
              path="/MaquinaDispensadora"
              element={<MaquinaDispensadora />}
            />
            <Route
              path="/Prescripcion"
              element={
                <ProtectedRoute requiredPhase={4} roomId="paciente_0">
                  <Prescripcion />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Crucigrama"
              element={
                <ProtectedRoute requiredPhase={5} roomId="paciente_0">
                  <Crucigrama />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Impresora"
              element={
                <ProtectedRoute requiredPhase={6} roomId="paciente_0">
                  <Impresora />
                </ProtectedRoute>
              }
            />
            <Route
              path="/DestinoFinal"
              element={
                <ProtectedRoute requiredPhase={6} roomId="paciente_0">
                  <FinalPaciente0 />
                </ProtectedRoute>
              }
            />
            {/* Rutas protegidas para Vuelo Secreto */}
            <Route path="/IntroVueloSecreto" element={<IntroVueloSecreto />} />
            <Route path="/Documentos" element={<Documentos />} />
            <Route
              path="/Torredecontrol"
              element={
                <ProtectedRoute requiredPhase={3} roomId="vuelo_secreto">
                  <Torredecontrol />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Numeropantalla"
              element={
                <ProtectedRoute requiredPhase={4} roomId="vuelo_secreto">
                  <Numeropantalla />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Pantalla"
              element={
                <ProtectedRoute requiredPhase={5} roomId="vuelo_secreto">
                  <Pantalla />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Sala"
              element={
                <ProtectedRoute requiredPhase={6} roomId="vuelo_secreto">
                  <Sala />
                </ProtectedRoute>
              }
            />
            <Route
              path="/FinalVueloSecreto"
              element={
                <ProtectedRoute requiredPhase={7} roomId="vuelo_secreto">
                  <FinalVueloSecreto />
                </ProtectedRoute>
              }
            />
          </Routes>
          <Analytics />
        </StripeContainer>
      </TimerProvider>
    </AuthProvider>
  );
}

export default App;
