import React, { useState, useContext, useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";

// Imágenes
import Fondovuelosecreto from "../imgs/cuartos/vuelosecreto/Fondovuelosecreto.webp";
import logovuelosecreto from "../imgs/cuartos/vuelosecreto/logovuelosecreto.png";

const MessagePopup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-4">
        <p className="font-roboto-mono text-black text-center">
          Ha llegado al lugar indicado para iniciar su exclusivo viaje. Para
          proceder con la solicitud del "vuelo secreto", le pedimos amablemente
          que envíe un mail al{" "}
          <span className="text-orange-500">correo electrónico</span> oculto en
          la carta.
        </p>
        <div className="mt-6 text-center">
          <button
            onClick={onClose}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-lg transition-colors"
          >
            Entendido
          </button>
        </div>
      </div>
    </div>
  );
};

const IntroVueloSecreto = () => {
  const { currentUser } = useAuth();
  const { timeLeft } = useContext(TimerContext);
  const [counters, setCounters] = useState({
    pink: 0,
    blue: 0,
    orange: 0,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState("");

  // Efecto para guardar el tiempo cada segundo
  useEffect(() => {
    const saveTime = async () => {
      if (currentUser && timeLeft) {
        const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
        try {
          await updateDoc(userDocRef, {
            currentTime: timeLeft,
          });
        } catch (error) {
          console.error("Error al guardar el tiempo:", error);
        }
      }
    };

    const interval = setInterval(saveTime, 1000);
    return () => clearInterval(interval);
  }, [currentUser, timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handleIncrement = (color) => {
    setCounters((prev) => ({
      ...prev,
      [color]: prev[color] + 1,
    }));
  };

  const handleDecrement = (color) => {
    setCounters((prev) => ({
      ...prev,
      [color]: Math.max(0, prev[color] - 1),
    }));
  };

  const handleConfirm = async () => {
    if (counters.pink === 5 && counters.blue === 6 && counters.orange === 4) {
      try {
        if (currentUser) {
          const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
          await updateDoc(userDocRef, { currentPhase: 2 });
        }

        setError("");
        setShowPopup(true);
      } catch (error) {
        console.error("Error al actualizar la fase:", error);
        setError("Ocurrió un error. Por favor, intenta de nuevo.");
      }
    } else {
      setError("Los números no son correctos. Intenta de nuevo.");
    }
  };

  return (
    <div className="min-h-screen relative">
      {/* Fondo */}
      <img
        src={Fondovuelosecreto}
        alt="Fondo Vuelo Secreto"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Timer en la esquina superior derecha */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Contenido */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Logo y título */}
          <div className="flex flex-col items-center mb-12">
            <img
              src={logovuelosecreto}
              alt="Logo Vuelo Secreto"
              className="w-48"
            />
          </div>

          {/* Texto principal */}
          <div className="text-black space-y-8 font-bold">
            <p className="font-roboto-mono leading-relaxed">
              Dentro del <span className="text-orange-500">a</span>jetreo{" "}
              <span className="text-pink-500">c</span>onstante del aeropuerto en
              el que te en<span className="text-blue-500">c</span>uentras, pocos
              conoc<span className="text-pink-500">e</span>n su mayor secreto:
              la existencia de un v<span className="text-blue-500">u</span>elo
              único, diseñado para activarse únicamente{" "}
              <span className="text-pink-500">c</span>uando se
              <span className="text-blue-500">a</span> necesario.
            </p>

            <p className="font-roboto-mono leading-relaxed">
              Este <span className="text-pink-500">v</span>uelo, creado por el
              ma<span className="text-orange-500">g</span>nat
              <span className="text-orange-500">e</span> Richard Br
              <span className="text-pink-500">a</span>nson, está reserv
              <span className="text-pink-500">a</span>do exc
              <span className="text-blue-500">l</span>usivame
              <span className="text-orange-500">n</span>te para sus{" "}
              <span className="text-blue-500">a</span>migos más cer
              <span className="text-orange-500">c</span>anos, qui
              <span className="text-blue-500">e</span>nes buscan una es
              <span className="text-pink-500">c</span>apada en su avión privado
              a su isla p<span className="text-blue-500">r</span>ivada. Sin
              embargo, existe una forma de{" "}
              <span className="text-pink-500">b</span>urlar el sistema{" "}
              <span className="text-orange-500">y</span> solicit
              <span className="text-blue-500">a</span>rlo por ti mism
              <span className="text-orange-500">@</span>, aunqu
              <span className="text-blue-500">e</span> no es nada fá
              <span className="text-orange-500">c</span>il.
            </p>

            <p className="font-roboto-mono leading-relaxed">
              <span className="text-pink-500">T</span>u aventu
              <span className="text-orange-500">ra c</span>omien
              <span className="text-pink-500">z</span>a aquí, a
              <span className="text-orange-500"> ki</span>lóm
              <span className="text-pink-500">e</span>tros de casa, en el
              aeropuer<span className="text-blue-500">t</span>o de Mo
              <span className="text-orange-500">n</span>terrey
              <span className="text-orange-500">.</span> Dirígete al{" "}
              <span className="text-orange-500">c</span>entr
              <span className="text-orange-500">o</span> de la Ter
              <span className="text-orange-500">m</span>inal A, do
              <span className="text-blue-500">n</span>de{" "}
              <span className="text-pink-500">s</span>e encuentran d
              <span className="text-blue-500">o</span>s escaleras eléctricas
              pegadas<span className="text-orange-500">.</span> Asó
              <span className="text-orange-500">m</span>ate bien y verás un logo
              especial. <span className="text-pink-500">P</span>
              ara <span className="text-blue-500">d</span>emostrar que estás{" "}
              <span className="text-pink-500">e</span>n el lugar correcto,
              cuenta <span className="text-blue-500">e</span>l número e
              <span className="text-orange-500">x</span>acto de cuadros rosas,
              naranjas y <span className="text-blue-500">a</span>zules que lo
              <span className="text-blue-500"> c</span>omponen.
            </p>
          </div>

          {/* Contadores */}
          <div className="flex flex-col items-center space-y-6 mt-12 mb-8">
            <div className="flex justify-center space-x-4">
              {/* Contador Rosa */}
              <div className="flex flex-col items-center">
                <button
                  onClick={() => handleIncrement("pink")}
                  className="text-pink-500 hover:text-pink-700 text-2xl mb-1"
                >
                  ▲
                </button>
                <div className="bg-pink-500 w-16 h-16 rounded-tl-2xl rounded-br-2xl flex items-center justify-center text-white text-xl">
                  {String(counters.pink).padStart(2, "0")}
                </div>
                <button
                  onClick={() => handleDecrement("pink")}
                  className="text-pink-500 hover:text-pink-700 text-2xl mt-1"
                >
                  ▼
                </button>
              </div>

              {/* Contador Azul */}
              <div className="flex flex-col items-center">
                <button
                  onClick={() => handleIncrement("blue")}
                  className="text-blue-500 hover:text-blue-700 text-2xl mb-1"
                >
                  ▲
                </button>
                <div className="bg-blue-500 w-16 h-16 rounded-tl-2xl rounded-br-2xl flex items-center justify-center text-white text-xl">
                  {String(counters.blue).padStart(2, "0")}
                </div>
                <button
                  onClick={() => handleDecrement("blue")}
                  className="text-blue-500 hover:text-blue-700 text-2xl mt-1"
                >
                  ▼
                </button>
              </div>

              {/* Contador Naranja */}
              <div className="flex flex-col items-center">
                <button
                  onClick={() => handleIncrement("orange")}
                  className="text-orange-500 hover:text-orange-700 text-2xl mb-1"
                >
                  ▲
                </button>
                <div className="bg-orange-500 w-16 h-16 rounded-tl-2xl rounded-br-2xl flex items-center justify-center text-white text-xl">
                  {String(counters.orange).padStart(2, "0")}
                </div>
                <button
                  onClick={() => handleDecrement("orange")}
                  className="text-orange-500 hover:text-orange-700 text-2xl mt-1"
                >
                  ▼
                </button>
              </div>
            </div>

            {/* Botón Confirmar y Mensaje de Error */}
            <div className="flex flex-col items-center space-y-2">
              <button
                onClick={handleConfirm}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-lg transition-colors"
              >
                Confirmar
              </button>
              {error && <p className="text-red-500 font-bold">{error}</p>}
            </div>
          </div>
        </div>
      </div>

      {/* PopUp de Mensaje */}
      {showPopup && <MessagePopup onClose={() => setShowPopup(false)} />}
    </div>
  );
};

export default IntroVueloSecreto;
