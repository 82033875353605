import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PasswordPopup from "./popUps/passwordPopup";
import { TimerContext } from "./timerContext_new";

import { useAuth } from "./AuthProvider";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase"; //Configuración de Firebase

import fondomesa from "../imgs/cuartos/asesinoimitador/fondomesa.webp";
import papel1 from "../imgs/cuartos/asesinoimitador/papel1.png";
import brevard from "../imgs/cuartos/asesinoimitador/brevard.webp";
import ContinueButton from "./buttons/continueButton";
import timeRedIcon from "../imgs/timer-red.svg";

const AsesinoImitador = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [backgroundSize, setBackgroundSize] = useState("100% 300px");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { timeLeft, startTimer } = useContext(TimerContext);
  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const verifyClave = (clave) => {
    if (clave === "adelante" || clave === "Adelante") {
      setShowPopup(false);
      setError("");
      setShowSecondPopup(true);
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  const verifyClaveSecondPopup = async (clave) => {
    if (clave === "Hilton") {
      try {
        if (currentUser) {
          const userDocRef = doc(db, "asesino_imitador", currentUser.uid);
          await updateDoc(userDocRef, { currentPhase: 2 });
        }

        setTimeout(() => {
          setShowSecondPopup(false);
          navigate("/paquete");
        }, 3000);
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  useEffect(() => {
    const timerStarted = localStorage.getItem("timerStartTime");
    if (!timerStarted) {
      startTimer();
    }

    const handleResize = () => {
      if (window.innerWidth < 768) {
        setBackgroundSize("100% 300px");
      } else {
        setBackgroundSize("100% 250px");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [startTimer]);

  //CurrentPhase
  useEffect(() => {
    const setInitialPhase = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "asesino_imitador", currentUser.uid);
        await setDoc(userDocRef, { currentPhase: 1 }, { merge: true });
      }
    };
    setInitialPhase();
  }, [currentUser]);

  const handleNextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  return (
    <div
      className="h-auto min-h-screen flex"
      style={{
        backgroundImage: `url(${fondomesa})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>
      <div className="m-auto my-6 p-6 h-auto rounded-lg bg-blueCTC/20 shadow-lg text-white mx-8">
        <div className="flex flex-col items-start justify-center lg:flex-row md:space-x-4">
          <div className="w-full lg:w-1/2 flex flex-col items-center justify-center">
            {/* Mostrar la carta de inmediato */}
            <div className="bg-slate-50 p-4 text-black font-roboto-mono mb-4 mt-0.5 opacity-100">
              <p className="text-sm md:text-base mb-4">
                En los últimos meses, el condado de Brevard ha sido el escenario
                de una serie de crímenes escalofriantes perpetrados por un
                imitador de un famoso asesino serial. Este criminal ha asesinado
                a decenas de personas por todo el país, sembrando terror desde
                que la primera víctima fue descubierta en 2003.
              </p>
              <p className="text-sm md:text-base">
                Actualmente, este individuo es considerado el más despiadado y
                peligroso en la historia reciente del país -de acuerdo al FBI y
                los medios de comunicación-, habiendo cobrado la vida de todo
                tipo de gente, incluyendo a su última víctima, Yosemite, la hija
                del presidente.
              </p>
            </div>
            <div
              className={`text-black font-roboto-mono h-72 flex flex-col items-center justify-center p-4 transition-opacity duration-500 ${
                step >= 2 ? "opacity-100" : "opacity-0"
              }`}
              style={{
                backgroundImage: `url(${papel1})`,
                backgroundSize: backgroundSize,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                visibility: step >= 1 ? "visible" : "hidden",
              }}
            >
              <p className="text-sm md:text-base mb-4 pt-6">
                En este momento, ustedes están dentro de la comisaría como
                asesores externos. Se esperan grandes cosas de este equipo, la
                élite de los investigadores mundiales.
              </p>
              <p className="text-sm md:text-base mb-4">
                Esta mañana, el jefe de la comisaría, Jones, recibió una carta
                firmada por "El Asesino Imitador". La carta dice lo siguiente...
              </p>
            </div>
          </div>

          <div
            className={`w-full lg:w-1/2 flex items-center justify-center transition-opacity duration-500 ${
              step >= 3 ? "opacity-100" : "opacity-0"
            }`}
            style={{ visibility: step >= 2 ? "visible" : "hidden" }}
          >
            <img className="w-auto h-auto" src={brevard} alt="Brevard"></img>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-center space-x-4">
          {step < 3 && (
            <button
              className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80"
              onClick={handleNextStep}
            >
              Siguiente
            </button>
          )}

          {step === 3 && <ContinueButton setShowPopup={setShowPopup} />}
        </div>
        {showPopup && (
          <PasswordPopup
            onSubmit={verifyClave}
            onClose={() => setShowPopup(false)}
            titleText="Siempre un paso..."
            placeholderText="Completa la frase"
            buttonText="Verificar frase"
            error={error}
          />
        )}
        {showSecondPopup && (
          <PasswordPopup
            onSubmit={verifyClaveSecondPopup}
            onClose={() => setShowSecondPopup(false)}
            titleText="Ingresa la ubicación"
            placeholderText="Ubicación"
            buttonText="Verificar ubicación"
            error={error}
          />
        )}
      </div>
    </div>
  );
};

export default AsesinoImitador;
