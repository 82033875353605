import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { TimerContext } from "./timerContext_new";

// Componentes
import BannerPaciente0 from "./paciente0/bannerpaciente0";
import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";

// Imágenes
import backgroundImage from "../imgs/people_background.webp";
import timeRedIcon from "../imgs/timer-red.svg";
import prescripcion from "../imgs/cuartos/Paciente0/prescripcion.webp";

// Firebase
import { useAuth } from "./AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const Prescripcion = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { timeLeft } = useContext(TimerContext);
  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePasswordSubmit = async (password) => {
    if (password === "8") {
      try {
        const userDocRef = doc(db, "paciente_0", currentUser.uid);
        await updateDoc(userDocRef, { currentPhase: 5 });

        setTimeout(() => {
          setShowPopup(false);
          setError("");
          navigate("/Crucigrama");
        }, 3000);
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      setError("Contraseña incorrecta. Intenta de nuevo.");
    }
  };

  return (
    <div
      className="min-h-screen bg-blueCTCbg bg-cover bg-top bg-no-repeat flex flex-col items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute top-8 text-center">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-white text-5xl font-bold bg-redCTC px-8 py-2">
            Paciente 0
          </h1>
        </div>
        <BannerPaciente0 />
        <div className="flex items-center justify-center p-2 rounded-md shadow-md text-white font-roboto-mono">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center cursor-pointer max-w-xl mt-48 mb-12 mx-4">
        <img src={prescripcion} alt="Sobre cerrado" className="w-full" />
        <ContinueButton
          setShowPopup={setShowPopup}
          bgColor="bg-greenPaciente0"
        />
      </div>

      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa la contraseña"
          buttonText="Verificar"
          error={error}
        />
      )}
    </div>
  );
};

export default Prescripcion;
