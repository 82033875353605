import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";
import PasswordPopup from "./popUps/passwordPopup";

// Imágenes
import Fondovuelosecreto from "../imgs/cuartos/vuelosecreto/Fondovuelosecreto.webp";
import logovuelosecreto from "../imgs/cuartos/vuelosecreto/logovuelosecreto.png";
import PantallaVuelos from "../imgs/cuartos/vuelosecreto/Pantalla.webp";

const Pantalla = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { timeLeft } = useContext(TimerContext);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [error, setError] = useState("");

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePasswordSubmit = async (password) => {
    if (password.toUpperCase() === "C6") {
      try {
        const userDocRef = doc(db, "vuelo_secreto", currentUser.uid);
        await updateDoc(userDocRef, { currentPhase: 6 });
        setError("");
        navigate("/Sala");
      } catch (error) {
        console.error("Error al actualizar la fase:", error);
        setError("Ocurrió un error. Por favor, intenta de nuevo.");
      }
    } else {
      setError("Puerta incorrecta. Intenta de nuevo.");
    }
  };

  return (
    <div className="min-h-screen relative">
      {/* Fondo */}
      <img
        src={Fondovuelosecreto}
        alt="Fondo Vuelo Secreto"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Contenido */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Logo y título */}
          <div className="flex flex-col items-center mb-8">
            <img
              src={logovuelosecreto}
              alt="Logo Vuelo Secreto"
              className="w-56"
            />
          </div>

          {/* Pantalla de vuelos */}
          <div className="flex flex-col items-center mb-8">
            <img
              src={PantallaVuelos}
              alt="Pantalla de Vuelos"
              className="w-full max-w-sm rounded-lg shadow-lg"
            />
          </div>

          {/* Texto e instrucciones */}
          <div className="text-center mb-8">
            <p className="text-black font-roboto-mono text-lg font-bold">
              Ingresa la puerta en la que sale el "vuelo especial"
            </p>
          </div>

          {/* Botón Continuar */}
          <div className="flex justify-center">
            <button
              onClick={() => setShowPasswordPopup(true)}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-8 rounded-lg transition-colors"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>

      {/* Password Popup */}
      {showPasswordPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPasswordPopup(false)}
          titleText="Ingresa el número de puerta"
          buttonText="Verificar"
          error={error}
        />
      )}
    </div>
  );
};

export default Pantalla;
